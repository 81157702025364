<template>
  <section class="stubs__section">
    <div class="stubs__controls">
      <button
        class="stubs__delete stubs__control"
        @click="stubController('deleteStub')"
      >
        Удалить
      </button>
      <button
        class="stubs__append stubs__control"
        @click="stubController('appendStub')"
      >
        Добавить
      </button>
      <button
        class="stubs__append stubs__control"
        @click="stubController('changeStub')"
      >
        Редактировать
      </button>
    </div>
    <div class="stubs__list">
      <v-radio
        class="stubs__item"
        :value="attrs.label"
        :attrs="attrs"
        v-model="active_stub"
        v-for="({ attrs = {} }, idx) in ADMIN_STUBS"
        :key="idx"
      />
    </div>

    <v-dialog
      class="stubs__dialog"
      :data="stubs_dialog"
      @adminSave="stubController('setStub')"
      @closeDialog="closeDialog"
    >
      <form class="stubs__dialog-form">
        <v-input
          :attrs="stub_form.fields.inputs.title.attrs"
          :errorBag="stub_form.fields.inputs.title.errorBag"
          v-model="stub_form.fields.inputs.title.value"
          class="stubs__dialog-input"
        />
        <v-input
          :attrs="stub_form.fields.inputs.descr.attrs"
          :errorBag="stub_form.fields.inputs.descr.errorBag"
          v-model="stub_form.fields.inputs.descr.value"
          class="stubs__dialog-input"
        />
        <v-input
          :attrs="stub_form.fields.inputs.btn_txt.attrs"
          :errorBag="stub_form.fields.inputs.btn_txt.errorBag"
          v-model="stub_form.fields.inputs.btn_txt.value"
          class="stubs__dialog-input"
        />
      </form>
    </v-dialog>
    <v-dialog
      class="stubs__dialog"
      :data="stubs_dialog_append"
      @adminSave="stubController('appendStub')"
      @closeDialog="closeDialog"
    >
      <form class="stubs__dialog-form">
        <v-input
          :attrs="stub_form.fields.inputs.title.attrs"
          :errorBag="stub_form.fields.inputs.title.errorBag"
          v-model="stub_form.fields.inputs.title.value"
          class="stubs__dialog-input"
        />
        <v-input
          :attrs="stub_form.fields.inputs.descr.attrs"
          :errorBag="stub_form.fields.inputs.descr.errorBag"
          v-model="stub_form.fields.inputs.descr.value"
          class="stubs__dialog-input"
        />
        <v-input
          :attrs="stub_form.fields.inputs.btn_txt.attrs"
          :errorBag="stub_form.fields.inputs.btn_txt.errorBag"
          v-model="stub_form.fields.inputs.btn_txt.value"
          class="stubs__dialog-input"
        />
      </form>
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { fields } from "../../helpers/stubs_dialog_data";
export default {
  name: "ZaglushkiViews",
  data() {
    return {
      active_stub: "",
      refresh_form: {},
      stub_form: {
        fields,
      },
      stubs_dialog: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Управление Заглушками",
      },
      stubs_dialog_append: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Добавить заглушку",
      },
      clone_stub_form: {},
    };
  },
  methods: {
    ...mapActions([
      "GET_ADMIN_STUBS",
      "GLOBAL_NOTIFICATIONS",
      "APPEND_STUB",
      "CHANGE_STUB",
      "DELETE_STUB",
    ]),
    async stubController(action, ...params) {
      let settings = params?.[0];
      settings;
      if (action == "appendStub") {
        try {
          if (this.stubs_dialog_append.options.visible) {
            if (this.stub_form.fields.inputs.title.value == "") {
              throw {
                title: "Пустое имя",
                text: "Выберите имя баннера (применяемый промокод)",
                status: "error",
                clean: false,
              };
            }
            await this.APPEND_STUB(this.stub_form.fields.inputs).then((res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              if (res?.status === 200) {
                this.refreshForm();
              }
              this.stubs_dialog_append.options.visible = false;
              setTimeout(async () => {
                await this.GET_ADMIN_STUBS();
                this.active_stub = this.ADMIN_STUBS?.[0]?.value;
              }, 500);
            });
          } else this.openDialog(this.stubs_dialog_append);

          await this.GET_ADMIN_STUBS();
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "setStub") {
        try {
          let newForm = this.stub_form.fields;
          let oldForm = this.clone_stub_form.fields;
          await this.CHANGE_STUB({ newForm, oldForm }).then((res) => {
            this.stubs_dialog.options.visible = false;
            this.GLOBAL_NOTIFICATIONS(res);
            if (res?.status === 200) {
              this.refreshForm();
            }
            setTimeout(async () => {
              await this.GET_ADMIN_STUBS();
              this.active_stub = this.ADMIN_STUBS?.[0]?.value;
            }, 500);
          });
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "deleteStub") {
        try {
          if (this.activeStub == "") {
            throw {
              title: "Заглушка не выбрана",
              text: "Выберите заглушку",
              status: "error",
              clean: false,
            };
          }
          await this.DELETE_STUB({ stub: this.activeStub }).then((res) => {
            this.GLOBAL_NOTIFICATIONS(res);
            if (res?.status === 200) {
              this.refreshForm();
            }
            // this.$store.state.ADMIN_STUBS = [];
            setTimeout(async () => {
              await this.GET_ADMIN_STUBS();
              this.active_stub = this.ADMIN_STUBS?.[0]?.value;
            }, 500);
          });
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }

      if (action == "changeStub") {
        try {
          if (this.activeStub == "") {
            throw {
              title: "Заглушка не выбрана",
              text: "Выберите заглушку",
              status: "error",
              clean: false,
            };
          }
          this.openDialog(this.stubs_dialog);
          this.stub_form.fields.inputs.title.value = this.activeStub;
          let stubData = Array.from(this.ADMIN_STUBS).filter(
            (item) => item.value == this.activeStub
          )?.[0];
          this.stub_form.fields.inputs.descr.value = stubData.descr;
          this.stub_form.fields.inputs.btn_txt.value = stubData.btn_txt;
          this.clone_stub_form = JSON.parse(JSON.stringify(this.stub_form));
          await this.GET_ADMIN_STUBS();
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
    },
    async closeDialog(data) {
      data.options.visible = false;
    },
    async openDialog(dialog) {
      dialog.options.visible = true;
    },
    refreshForm(dialog) {
      this.stub_form = JSON.parse(JSON.stringify(this.refresh_form));
      if (dialog !== undefined) {
        this[dialog].options.visible = false;
      }
    },
  },
  computed: {
    ...mapGetters(["ADMIN_STUBS", "FOLDER_IMAGES"]),
    activeStub() {
      return this.active_stub;
    },
  },
  async mounted() {
    this.refresh_form = JSON.parse(JSON.stringify(this.stub_form));
    await this.GET_ADMIN_STUBS();
    this.active_stub = this.ADMIN_STUBS?.[0]?.value || "";
  },
};
</script>
<style lang="scss" scoped>
.stubs {
  /* .stubs__section */

  &__section {
  }

  /* .stubs__controls */

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  /* .stubs__delete */

  &__delete {
  }

  /* .stubs__control */

  &__control {
    max-width: 24%;
    width: 100%;
    padding: 14px;
    border: 1px solid #ff6800;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
  }

  /* .stubs__append */

  &__append {
  }

  /* .stubs__list */

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* .stubs__item */

  &__item {
  }

  /* .stubs__item-image */

  &__item-image {
  }
  /* .stubs__dialog-image */

  &__dialog-image {
    max-width: 600px;
    pointer-events: none;
  }
  /* .stub__dialog-action */

  &__dialog-action {
    margin-bottom: 12px;
  }
  /* .stub__dialog-location */

  &__dialog-location {
    margin-bottom: 12px;
  }
  /* .stubs__dialog-form */

  &__dialog-form {
  }
  /* .stubs__dialog-input */

  &__dialog-input {
    margin-bottom: 12px;
  }
  /* .stubs__dialog-images */

  &__dialog-images {
    display: flex;
    overflow-x: auto;
    img {
      max-width: 25%;
      margin-right: 12px;
      padding: 12px;
      cursor: pointer;
      transition: 0.3s ease;
      border-radius: 10px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: $unactive-accent;
      }
    }
  }
}
</style>

<style lang="scss">
.stub__dialog-action {
  margin-bottom: 12px;
}

.stub__dialog-location {
  margin-bottom: 12px;
}

.stubs__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 49%;
  min-height: 168px;
  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    height: inherit;
    img {
      margin-top: 12px;
    }
  }
}
.stubs__item-image {
  max-width: 100%;
}
</style>

