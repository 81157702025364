const fields = {
  inputs: {
    title: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "title",
        placeholder: "Заголовок",
        label: "Заголовок",
        type: "text",
        always_type: "text",
      },
    },
    descr: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "descr",
        placeholder: "Текст открывшегося диалога",
        label: "Текст открывшегося диалога",
        type: "text",
        always_type: "text",
      },
    },
    btn_txt: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "btn_txt",
        placeholder: "Текст кнопки",
        label: "Текст кнопки",
        type: "text",
        always_type: "text",
      },
    },
  },
};

export { fields };
